import { FaCreativeCommonsNd } from "react-icons/fa";
import { useState } from "react";
import Fade from "react-reveal/Fade";
import { RxCross2 } from "react-icons/rx";
export const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <>
      <div className="flex justify-between mt-0 py-2 px-6 bg-black">
        <div>
          <img
            decoding="async"
            height="10"
            src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706708415/TimeOnSolana/Time_Logo_reenwa.png"
            data-src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706708415/TimeOnSolana/Time_Logo_reenwa.png"
            className="logo lazyloaded"
            alt="logo"
          />
        </div>

        <div className="py-2">
          <div className=" flex items-center justify-center  ">
            <div className=" lg:flex hidden  space-x-6 text-gray-700">

              <a
                href="https://doc.ontime.club"
                target="_blank"
                rel="noreferrer"
              >

              <button className="bg-[#FAE232] border-none py-2 px-6 mb-3 text-black rounded-xl text-2xl">
                Whitepaper
              </button>
              </a>

              <a
                href="https://meme.timeonsolana.xyz/"
                target="_blank"
                rel="noreferrer"
              >

                <p className="text-white text-xl flex items-center justify-center  rounded-full p-2">
                MemeGen
                </p>
              </a>

              <a
                href="https://t.me/timeonsolanacovers"
                target="_blank"
                rel="noreferrer"
              >

                <p className="text-white text-xl flex items-center justify-center  rounded-full p-2">
                Magazine
                </p>
              </a>

              <a
                href="https://ontime.club"
                target="_blank"
                rel="noreferrer"
              >

                <p className="text-white text-xl flex items-center justify-center  rounded-full p-2">
                OnTIME
                </p>
              </a>

              <a
                href="https://t.me/timeonsolana"
                target="_blank"
                rel="noreferrer"
              >
                <div className="border-2 border-black flex items-center justify-center  rounded-full w-12 h-12 p-2">
                  <img
                    alt="img"
                    src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706733920/Pope/telegram-white_mbdh6r.png"
                  />
                </div>
              </a>
              {/* Twitter */}
              <a
                href="https://twitter.com/timeonsolana"
                target="_blank"
                rel="noreferrer"
              >
                <div className="border-2 border-black flex items-center justify-center  rounded-full w-12 h-12 p-2">
                  <img
                    alt="img"
                    src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706733920/Pope/twitter-white_cp5mb1.png"
                  />
                </div>
              </a>

              {/* </div> */}
            </div>
          </div>
        </div>

        <div
          className="text-6xl lg:hidden text-white cursor-pointer"
          onClick={() => setShowSideBar(true)}
        >
          {!showSideBar && <FaCreativeCommonsNd />}
        </div>
        {showSideBar && (
          <Fade right>
            <div className="w-3/4 bg-[rgba(0,0,0,0.9)] fixed top-0 right-0 h-screen z-50 ">
              <div className=" w-full text-xl font-extrabold p-4 text-left  from-neutral-200">
                <div onClick={() => setShowSideBar(false)} className="p-2">
                  <RxCross2 className="text-white cursor-pointer" />
                </div>

                <div className="items-center justify-center text-center">

                <a target="_blank" href="https://t.me/timeonsolana" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    Telegram
                  </button>
                </a>

                <a target="_blank" href="https://twitter.com/timeonsolana" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    Twitter
                  </button>
                </a>

                <a target="_blank" href="https://doc.ontime.club" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    Whitepaper
                  </button>
                </a>

                <a target="_blank" href="https://ontime.club" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    OnTIME Club
                  </button>
                </a>

                </div>
              </div>
            </div>
          </Fade>
        )}
      </div>
    </>
  );
};
