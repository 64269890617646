import Marquee from "react-fast-marquee";
export const Footer = () => {
  return (
    <>
      <footer className="text-center bgradient lg:text-left bg-gray-100 text-gray-600  bottom-0 w-full">
        {/* Marquee section */}
        <div className=" bg-[#E90606] my-0 ">
          <div className="lg:flex justify-between w-5/5 m-auto">
            <div className="w-full bgradient items-center justify-center bg-[#000000] text-white lg:text-3xl md:text-3xl py-4 overflow-y-hidden text-3xl">
              <Marquee
                speed={60}
                gradient={false}
                className="overflow-y-hidden"
              >
                <p className="overflow-y-hidden text-black m-auto">
                  This is our TIME on Solana.  Let's make the most of it.
                </p>

              
              </Marquee>
            </div>
          </div>
        </div>
        {/* Second section starts */}



      </footer>
    </>
  );
};
