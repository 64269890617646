import "./App.css";
import { useEffect, useState } from "react";
import { Footer } from "./components/Footer";
import { Loader } from "./components/Loader";
import { Header } from "./components/Header";
import { Roadmap } from "./components/RoadMap";
import { Roadmap3 } from "./components/RoadMap3";


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="App overflow-x-hidden font-Roboto bg-[#000]">
      <Header />
      {loading && <Loader loading={loading} />}
      <Roadmap />
{/*      <Client /> */}
      <Roadmap3 />
      <Footer />
    </div>
  );
}

export default App;
